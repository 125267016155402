<template>
	<div class="page page-iframe">
		<h2 class="is-title is-inverted is-simple has-small-margin">{{ title }}</h2>
		<div class="page-background is-short" :style="{ backgroundImage: background }"></div>
		<div class="iframe-wrapper"><iframe v-if="iframeUrl" allow="fullscreen" :src="iframeUrl"></iframe></div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import meta from '../utils/meta';
	import session from '../utils/session';
	import Vue from 'vue';
	import LoginModal from '../components/LoginModal';

	export default {
		name: 'IframePage',

		data() {
			return {
				title: null,
				subtitle: null,
				background: null,
				iframeUrl: null,
				session: session.state,
			};
		},
		beforeRouteEnter(to, from, next) {
			let url = `/page-e-posters`;
			if (to.meta.type === 'vod') {
				url = `/page-videos-on-demand`;
			}
			$http.get(url).then(
				data => {
					next(vm => vm.setData(data.data));
				},
				err => {
					if (err.response.status === 403) {
						window.sessionStorage.setItem('afterLogin', JSON.stringify({ name: to.name, params: to.params }));
						Vue.prototype.$modal.show(
							LoginModal,
							{},
							{
								classes: 'login-modal',
								width: '90%',
								maxWidth: 550,
								height: 'auto',
								adaptive: true,
							},
							{
								'before-close': () => {
									next(false);
									window.sessionStorage.removeItem('afterLogin');
									this.$Progress.finish();
								},
							}
						);
					}
				}
			);
		},
		beforeRouteUpdate(to, from, next) {
			let url = `/page-e-posters`;
			if (to.meta.type === 'vod') {
				url = `/page-videos-on-demand`;
			}
			$http.get(url).then(data => {
				this.setData(data.data);
				next();
			});
		},
		methods: {
			setData(data) {
				this.title = data.title;
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background && data.background.url ? `url('${this.$options.filters.imageUrl(data.background)}')` : null;
				if (this.session.loggedIn) {
					let token = session.generateToken();
					this.iframeUrl = data.iframe_url.replace('{token}', token);
				}
			},
		},
	};
</script>

<style scoped></style>
